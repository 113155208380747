<template>
  <c-box w="100%" :pb="['80px', null]">
    <c-flex
      :flex-direction="['column', 'row-reverse']"
      justifyContent="space-between"
      flexGrow="1"
    >
      <c-flex
        flex-direction="column"
        :ml="!isClient ? [null, '164px'] : [null, '60px']"
      >
        <c-image
          :src="getPhotoUser(profile.photoUrl)"
          :alt="profile.firstName"
          :w="['140px', '244px']"
          :h="['140px', '244px']"
          mx="auto"
          mt="35px"
          border-radius="50%"
        />
        <input
          style="display: none"
          type="file"
          accept="image/*"
          ref="inputAvatar"
          @change="onFileChange"
        />
        <c-button
          variant="outline"
          variant-color="brand"
          border-radius="25px"
          h="42px"
          mx="auto"
          mt="28px"
          :is-loading="isUploading"
          loading-text="Uploading"
          @click="
            $refs.inputAvatar.value = '';
            $refs.inputAvatar.click();
          "
        >
          Ganti foto
        </c-button>
      </c-flex>

      <ProfileFormDetail
        v-if="isSuperAdmin || isAdmin"
        @submit="onSubmit"
        v-model="profile"
      />
      <ProfileNutriFormDetail
        v-if="isNutri"
        @submit="onSubmit"
        v-model="profile"
      />
      <ProfileClientFormDetail
        v-if="isClient"
        @submit="onSubmit"
        :countries="countries"
        :states="states"
        :cities="cities"
        v-model="profile"
      />
    </c-flex>
  </c-box>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ProfileFormDetail from "./form.vue";
import ProfileNutriFormDetail from "./form-nutri.vue";
import ProfileClientFormDetail from "./form-client.vue";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "ProfileDetailPage",
  components: {
    ProfileFormDetail,
    ProfileNutriFormDetail,
    ProfileClientFormDetail,
  },
  mixins: [generalMixin],
  data() {
    return {
      profile: {},
      isUploading: false,
      countries: [],
      states: [],
      cities: [],
    };
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    ...mapGetters({
      dataProfile: "profile/dataProfile",
      axios: "axios",
    }),
    isSuperAdmin() {
      return this.user.role === "super_admin";
    },
    isAdmin() {
      return this.user.role === "admin";
    },
    isClient() {
      return this.user.role === "client";
    },
    isNutri() {
      return this.user.role === "nutritionist";
    },
    countryId() {
      return this.countries?.find((it) => it.name === this.profile.country)?.id;
    },
    stateId() {
      return this.states?.find((it) => it.name === this.profile.province)?.id;
    },
    cityId() {
      return this.cities?.find((it) => it.name === this.profile.city)?.id;
    },
  },
  watch: {
    countryId(id) {
      this.getStates(id);
    },
    stateId(id) {
      this.getCities(id);
    },
    dataProfile: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.profile = val;
      },
    },
  },
  methods: {
    ...mapActions({
      // superadmin
      getProfileSuperadmin: "profile/getProfileSuperadmin",
      updateProfileSuperadmin: "profile/updateProfileSuperadmin",

      // admin
      getProfileAdmin: "profile/getProfileAdmin",
      updateProfileAdmin: "profile/updateProfileAdmin",

      // nutritionist
      n_getProfile: "profile/getProfile",
      n_updateProfile: "profile/updateProfile",

      // client
      getProfile: "profile/getProfileClient",
      updateProfile: "profile/updateProfileClient",
      uploadAvatar: "profile/uploadAvatar",
    }),
    onFileChange(input) {
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0];
        this.isUploading = true;
        if (file.size > 2097152) {
          this.isUploading = false;
          return this.$toast({
            title: "Failed",
            description: `This file can't be uploaded, because it (${this.formatBytes(
              file.size
            )}) exceeds the maximum file size (2 MB).`,
            status: "error",
            duration: 10000,
            position: "bottom-right",
            variant: "subtle",
          });
        }
        // Upload avatar to API
        let formData = new FormData();
        formData.append("file", input.target.files[0]);
        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        this.axios
          .post(`/v1/users/upload`, formData)
          .then((it) => {
            this.isUploading = false;
            this.profile.photoUrl = it.data.data.url;
          })
          .then((it) => {
            console.log("@upload-avatar", it);
            this.onSubmit();
          })
          .catch(() => {
            this.$toast({
              title: "Failed",
              description: "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
    },
    onSubmit() {
      if (this.isSuperAdmin) {
        this.updateProfileSuperadmin({ profile: this.profile })
          .then(() => {
            this.$toast({
              title: "Success",
              description: "Your profile has been updated.",
              status: "success",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          })
          .catch((err) => {
            this.$toast({
              title: "Failed",
              description: err.data.message ?? "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
      if (this.isAdmin) {
        this.updateProfileAdmin({ profile: this.profile })
          .then(() => {
            this.$toast({
              title: "Success",
              description: "Your profile has been updated.",
              status: "success",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          })
          .catch((err) => {
            this.$toast({
              title: "Failed",
              description: err.data.message ?? "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
      if (this.isNutri) {
        this.n_updateProfile({ profile: this.profile })
          .then(() => {
            this.$toast({
              title: "Success",
              description: "Your profile has been updated.",
              status: "success",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          })
          .catch((err) => {
            this.$toast({
              title: "Failed",
              description: err.data.message ?? "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
      if (this.isClient) {
        this.updateProfile({ profile: this.profile })
          .then(() => {
            this.$toast({
              title: "Success",
              description: "Your profile has been updated.",
              status: "success",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          })
          .catch((err) => {
            this.$toast({
              title: "Failed",
              description: err.data.message ?? "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
    },
    async getCountries() {
      let { data } = await this.axios
        .get("/v1/general/countries")
        .then((it) => it.data);

      if (!data) return;
      this.countries = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        };
      });
    },
    async getStates(countryId) {
      let { data } = await this.axios
        .get(`/v1/general/states?countryId=${countryId}`)
        .then((it) => it.data);

      this.states = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        };
      });
    },
    async getCities(stateId) {
      let { data } = await this.axios
        .get(`/v1/general/cities?stateId=${stateId}`)
        .then((it) => it.data);

      if (!data) return;
      this.cities = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        };
      });
    },
  },
  async mounted() {
    if (this.isSuperAdmin) {
      await this.getProfileSuperadmin();
    }
    if (this.isAdmin) {
      await this.getProfileAdmin();
    }
    if (this.isNutri) {
      await this.n_getProfile();
    }
    if (this.isClient) {
      await this.getProfile();
    }
    await Promise.all([
      this.getCountries(),
      this.getStates(),
      this.getCities(),
    ]);

  },
};
</script>
