<template>
  <c-form-control mb="25px">
    <c-form-label for="firstName" font-size="14px" color="#555555">
      {{ label }}
    </c-form-label>
    <slot>
      <c-input
          v-bind="$attrs"
          v-on="$listeners"
          h="62px"
          border="1px solid #c4c4c4"
          border-radius="md"
          v-chakra="{
            _disabled: {
              opacity: 1,
              background: '#f2f2f2'
            }
          }"
      />
    </slot>
  </c-form-control>
</template>

<script>
export default {
  name: 'form-field',
  props: ['label'],
}
</script>

<style scoped>

</style>
