<template>
  <c-box flexGrow="1">
    <h1
      v-chakra
      :d="['none', 'block']"
      font-weight="bold"
      font-size="18px"
      color="#111111"
      mt="0"
      mb="25px"
    >
      Data Diri
    </h1>
    <form v-chakra :mt="['70px', 0]">
      <form-field
        label="Nama Depan"
        placeholder="Nama Depan"
        type="text"
        v-model="value.firstName"
      />
      <form-field
        label="Nama Belakang"
        placeholder="Nama Belakang"
        type="text"
        v-model="value.lastName"
      />
      <form-field
        label="Tanggal Lahir"
        placeholder="DD-MM-YYYY"
        type="date"
        v-model="value.birthDate"
      >
        <v-date-picker
          :masks="{
            input: 'DD-MM-YYYY',
          }"
          v-model="value.birthDate"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <c-flex align-items="center">
              <c-input
                h="48px"
                border="1px solid #c4c4c4"
                border-radius="md"
                :value="inputValue"
                readonly
                @focus="togglePopover()"
              />
            </c-flex>
          </template>
        </v-date-picker>
      </form-field>
      <form-field
        label="Alamat"
        placeholder="Alamat"
        type="text"
        v-model="value.address"
      />
      <c-form-control is-required marginBottom="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Negara
        </c-form-label>
        <c-select
          placeholder="Pilih Negara"
          v-model="value.country"
          height="62px"
        >
          <option
            v-for="item in countries"
            :key="item.name"
            :value="item.name"
          >
            {{ item.label }}
          </option>
        </c-select>
      </c-form-control>
      <c-form-control is-required marginBottom="25px">
        <c-form-label
          fontSize="14"
          color="#555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Provinsi
        </c-form-label>
        <c-input-group size="md">
          <c-select
            placeholder="Pilih Provinsi"
            v-model="value.province"
            height="62px"
          >
            <option
              v-for="item in states"
              :key="item.name"
              :value="item.name"
            >
              {{ item.label }}
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>
      <c-form-control is-required marginBottom="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeight="400"
          fontFamily="Roboto"
        >
          Kota
        </c-form-label>
        <c-select v-chakra placeholder="Pilih Kota" v-model="value.city" height="62px">
          <option v-for="item in cities" :key="item.name" :value="item.name">
            {{ item.label }}
          </option>
        </c-select>
      </c-form-control>
      <form-field
        label="Kode Pos"
        placeholder="Kode Pos"
        type="text"
        v-model="value.zipCode"
      />
      <form-field
        @input="onlyNumber"
        label="Nomor Telepon"
        placeholder="Nomor Telepon"
        type="text"
        v-model="value.phone"
      />
      <form-field
        label="Email"
        placeholder="Email"
        type="email"
        v-model="value.email"
        disabled
      />
      <c-flex mt="28px" justify-content="space-between">
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          mr="20px"
          color="#555555"
          as="router-link"
          :to="{ name: 'client.index' }"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="brand"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :isDisabled="isDisabledSubmit"
          @click="$emit('submit')"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>
  </c-box>
</template>

<script>
import FormField from "../form-field.vue";

export default {
  name: "ProfileDetailForm",
  props: ["value", "countries", "states", "cities"],
  components: {
    FormField,
  },
  computed: {
    isDisabledSubmit() {
      return !this.value.country || !this.value.province || !this.value.city;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.value = val;
        } else {
          this.value = {
            address: null,
            birthDate: null,
            birthPlace: null,
            city: null,
            country: null,
            email: null,
            firstName: null,
            gender: null,
            id: null,
            lastName: null,
            phone: null,
            photoUrl: null,
            province: null,
            zipCode: null,
          };
        }
      },
    },
  },
  methods: {
    onlyNumber(event) {
      if (!event.target.value.replace(/\D/g, "").match(/(\d{0,15})/).input) {
        event.target.value = "";
        this.value.phone = "";
      }
    },
  },
};
</script>
