<template>
  <c-box flexGrow="1">
    <h1
      v-chakra
      :d="['none', 'block']"
      font-weight="bold"
      font-size="18px"
      color="#111111"
      mb="30px"
    >
      Edit Profile
    </h1>
    <form v-chakra :mt="['70px', 0]">
      <c-form-control
        :isInvalid="$v.value.firstName.$invalid"
        is-required
        mb="25px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Nama Depan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            placeholder="Masukkan Nama Depan"
            height="62px"
            v-model="value.firstName"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Nama Belakang
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            placeholder="Masukkan Nama Belakang"
            height="62px"
            v-model="value.lastName"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        :isInvalid="$v.value.email.$invalid"
        is-required
        mb="80px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Email
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="email"
            error-border-color="red.300"
            placeholder="Masukkan Email"
            height="62px"
            variant="filled"
            disabled
            v-model="value.email"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        is-required
        :is-invalid="oldPassword && $v.oldPassword.$invalid"
        mt="80px"
        mb="25px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Password Lama
        </c-form-label>
        <c-input-group>
          <c-input
            :type="shownPassword.includes('oldPassword') ? `text` : `password`"
            placeholder="Masukkan Password Lama"
            v-model="oldPassword"
            h="62px"
            tabindex="1"
          />
          <c-input-right-element>
            <c-button
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              transform="translateY(-20%)"
              @click="togglePasswordVisibility('oldPassword')"
            >
              <c-image
                :src="require('@/assets/icon-show-password.svg')"
                alt="toggle password visibility"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control
        is-required
        :is-invalid="newPassword && $v.newPassword.$invalid"
        mb="25px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Password Baru
        </c-form-label>
        <c-input-group>
          <c-input
            :type="shownPassword.includes('newPassword') ? `text` : `password`"
            placeholder="Masukkan Password Baru"
            v-model="newPassword"
            h="62px"
            tabindex="1"
          />
          <c-input-right-element>
            <c-button
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              transform="translateY(-20%)"
              @click="togglePasswordVisibility('newPassword')"
            >
              <c-image
                :src="require('@/assets/icon-show-password.svg')"
                alt="toggle password visibility"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.minLength"
          color="red.500"
        >
          Password minimal 8 karakter
        </c-form-helper-text>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.hasNumber"
          color="red.500"
        >
          Password harus mengandung angka
        </c-form-helper-text>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.hasLowercaseLetter"
          color="red.500"
        >
          Password harus mengandung huruf kecil
        </c-form-helper-text>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.hasUppercaseLetter"
          color="red.500"
        >
          Password harus mengandung huruf kapital
        </c-form-helper-text>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.hasSpecialCharacter"
          color="red.500"
        >
          Password harus mengandung karakter spesial @$!%()*?&
        </c-form-helper-text>
      </c-form-control>

      <c-form-control
        is-required
        :is-invalid="
          newPasswordConfirmation && $v.newPasswordConfirmation.$invalid
        "
        mb="25px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Konfirmasi Password Baru
        </c-form-label>
        <c-input-group>
          <c-input
            :type="
              shownPassword.includes('newPasswordConfirmation')
                ? `text`
                : `password`
            "
            placeholder="Ulangi Password Baru"
            v-model="newPasswordConfirmation"
            h="62px"
            tabindex="1"
          />
          <c-input-right-element>
            <c-button
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              transform="translateY(-20%)"
              @click="togglePasswordVisibility('newPasswordConfirmation')"
            >
              <c-image
                :src="require('@/assets/icon-show-password.svg')"
                alt="toggle password visibility"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
        <!--Show error here-->
        <c-form-helper-text
          v-if="!$v.newPasswordConfirmation.sameAs"
          color="red.500"
        >
          Password baru tidak sama
        </c-form-helper-text>
      </c-form-control>

      <c-flex mt="70px" justify-content="space-between">
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          @click="$router.push('/')"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="brand"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :isDisabled="isDisabledSubmit"
          @click="onSubmit"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>
  </c-box>
</template>

<script>
import { mapGetters } from "vuex";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  name: "ProfileDetailForm",
  props: ["value"],
  components: {},
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
      shownPassword: [],
      isSucceeded: false,
    };
  },
  computed: {
    ...mapGetters({ axios: "axios" }),
    isDisabledSubmit() {
      if (this.$v.value.firstName.$invalid) {
        return true;
      }
      return false;
    },
  },
  watch: {
    immediate: true,
    value: {
      handler(val) {
        if (val) {
          this.value = val;
        } else {
          this.value = {
            firstName: null,
            lastName: null,
            email: null,
            photoUrl: null,
          };
        }
      },
    },
  },
  validations: {
    value: {
      firstName: { required },
      email: { required, email },
    },
    oldPassword: { required },
    newPassword: {
      required,
      minLength: minLength(8),
      hasNumber(value) {
        return /\d/.test(value);
      },
      hasLowercaseLetter(value) {
        return /[a-z]/.test(value);
      },
      hasUppercaseLetter(value) {
        return /[A-Z]/.test(value);
      },
      hasSpecialCharacter(value) {
        let reSpecialCharacter = /[@$!%()*?&]/;
        return reSpecialCharacter.test(value);
      },
    },
    newPasswordConfirmation: { required, sameAs: sameAs("newPassword") },
  },
  methods: {
    togglePasswordVisibility(name) {
      if (this.shownPassword.includes(name)) {
        this.shownPassword = this.shownPassword.filter((it) => it !== name);
      } else {
        this.shownPassword.push(name);
      }
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.value.firstName.$invalid) {
        this.$emit("submit");
      }
      if (
        this.$v.oldPassword.$invalid ||
        this.$v.newPassword.$invalid ||
        this.$v.newPasswordConfirmation.$invalid
      )
        return;

      if (
        this.oldPassword &&
        this.newPassword &&
        this.newPasswordConfirmation
      ) {
        this.axios
          .put(`/v1/users/update-password`, {
            currentPassword: this.oldPassword,
            newPassword: this.newPassword,
          })
          .then(() => {
            this.$v.$reset();
            this.oldPassword = null;
            this.newPassword = null;
            this.newPasswordConfirmation = null;
            this.$router.replace("/login");
          })
          .catch((err) => {
            this.$toast({
              title: "Failed",
              description:
                err.response.data.message ?? "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
    },
  },
};
</script>
