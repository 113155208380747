<template>
  <c-box flexGrow="1">
    <h1
      v-chakra
      :d="['none', 'block']"
      font-weight="bold"
      font-size="18px"
      color="#111111"
      mb="30px"
    >
      Data Diri
    </h1>
    <form v-chakra :mt="['70px', 0]">
      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          ID Nutritionist
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            placeholder="Masukkan ID Nutritionist"
            height="62px"
            variant="filled"
            disabled
            v-model="value.id"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control :isInvalid="!value.str" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          STR
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan STR"
            height="62px"
            v-model="value.str"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control :isInvalid="!value.firstName" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Nama Depan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Nama Depan"
            height="62px"
            v-model="value.firstName"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Nama Belakang
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            placeholder="Masukkan Nama Belakang"
            height="62px"
            v-model="value.lastName"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        display="flex"
        mb="10px"
        alignItems="center"
        justifyContent="space-between"
      >
        <c-form-label
          color="#555555"
          fontWeigh="500"
          fontSize="18px"
          lineHeight="27px"
          fontFamily="Roboto"
        >
          Pendidikan
        </c-form-label>
      </c-form-control>
      <c-box
        v-for="(item, index) in value.education"
        :key="'edu' + index"
        position="relative"
      >
        <c-box position="absolute" top="40px" right="-45px">
          <c-button
            @click="removeEducation(index)"
            ml="18px"
            min-width="auto"
            variant-color="red"
            variant="link"
          >
            <c-image
              :src="require('@/assets/icon-trash-line.svg')"
              alt="empty"
            />
          </c-button>
        </c-box>
        <c-form-control mb="25px">
          <c-form-label
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Degre
          </c-form-label>
          <c-input-group size="md">
            <c-select
              v-model="item.level"
              height="62px"
              placeholder="Masukkan Level"
            >
              <option
                v-for="item in degre"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </c-select>
          </c-input-group>
        </c-form-control>

        <c-form-control is-required mb="25px">
          <c-form-label
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Gelar
          </c-form-label>
          <c-input-group size="md">
            <c-input
              type="text"
              placeholder="Masukkan Gelar"
              height="62px"
              v-model="item.title"
            />
          </c-input-group>
        </c-form-control>

        <c-form-control mb="25px">
          <c-form-label
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Universitas
          </c-form-label>
          <c-input-group size="md" z-index="1">
            <ModelListSelect
              :list="dataUniversities"
              v-model="item.university"
              option-value="id"
              option-text="name"
              placeholder="Masukkan Universitas"
              style="
                height: 62px;
                display: inline-flex;
                align-items: center;
                font-size: 16px;
              "
            />
          </c-input-group>
          <c-input-group v-if="item.university === '0'" size="md" mt="25px">
            <c-input
              type="text"
              error-border-color="red.300"
              placeholder="Masukkan Universitas"
              height="62px"
              @keyup="onSetOtherUniversity($event, index)"
              v-model="item.otherUniversity"
            />
          </c-input-group>
        </c-form-control>
      </c-box>
      <c-button
        v-if="value.education && value.education.length < 2"
        @click="addEducation"
        :isDisabled="isEmptyEducation"
        variant-color="brand"
        variant="outline"
        borderRadius="60px"
        h="62px"
        w="100%"
        left-icon="add"
        mb="25px"
      >
        Tambah Pendidikan
      </c-button>

      <c-form-control :isInvalid="!value.specialization" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Spesialisasi
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Spesialisasi"
            height="62px"
            v-model="value.specialization"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        display="flex"
        mb="10px"
        alignItems="center"
        justifyContent="space-between"
      >
        <c-form-label
          color="#555555"
          fontWeigh="500"
          fontSize="18px"
          lineHeight="27px"
          fontFamily="Roboto"
        >
          Pengalaman Bekerja
        </c-form-label>
      </c-form-control>
      <c-box
        v-for="(item, index) in value.workExperiences"
        :key="index"
        position="relative"
      >
        <c-box position="absolute" top="40px" right="-45px">
          <c-button
            @click="removeWorkExperience(index)"
            ml="18px"
            min-width="auto"
            variant-color="red"
            variant="link"
          >
            <c-image
              :src="require('@/assets/icon-trash-line.svg')"
              alt="empty"
            />
          </c-button>
        </c-box>
        <form-field
          label="Nama Perusahaan"
          placeholder="Masukkan Pengalaman Bekerja"
          type="text"
          v-model="item.companyName"
        />
        <c-flex align="center">
          <form-field
            :style="{ width: '50%', marginRight: '20px' }"
            label="Tahun Masuk"
            placeholder="Masukkan Tahun Masuk"
            type="text"
            v-model="item.startYear"
          />
          <form-field
            :style="{ width: '50%' }"
            label="Tahun Keluar"
            placeholder="Masukkan Tahun Keluar"
            type="text"
            v-model="item.endYear"
          />
        </c-flex>
      </c-box>
      <c-button
        @click="addWorkExperience"
        :isDisabled="isEmptyworkExperiences"
        variant-color="brand"
        variant="outline"
        borderRadius="60px"
        h="62px"
        w="100%"
        left-icon="add"
      >
        Tambah Pengalaman Kerja
      </c-button>

      <c-flex mt="28px" justify-content="space-between">
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          as="router-link"
          :to="{ name: 'client.index' }"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="brand"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :isDisabled="isDisabledSubmit"
          @click="$emit('submit')"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormField from "../form-field.vue";
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";

export default {
  name: "ProfileDetailForm",
  props: ["value"],
  components: {
    FormField,
    ModelListSelect,
  },
  data() {
    return {
      degre: [
        { value: "d1", name: "D1" },
        { value: "d3", name: "D3" },
        { value: "d4", name: "D4" },
        { value: "s1", name: "S1" },
        { value: "s2", name: "S2" },
        { value: "s3", name: "S3" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      universities: "general/universities",
    }),
    dataUniversities() {
      return this.universities.map((dt) => {
        dt.id = dt.id.toString();
        return dt;
      });
    },
    isDisabledSubmit() {
      if (
        this.value.str &&
        this.value.firstName &&
        this.value.education.length > 0 &&
        !this.isEmptyEducation &&
        this.value.specialization &&
        !this.isEmptyworkExperiences
      ) {
        return false;
      }
      return true;
    },
    isEmptyworkExperiences() {
      if (this.value?.workExperiences) {
        let emptyObj = this.value.workExperiences.findIndex(
          (dt) => !dt.companyName || !dt.endYear || !dt.startYear
        );
        if (emptyObj > -1) return true;
      }
      return false;
    },
    isEmptyEducation() {
      if (this.value?.education) {
        let emptyObj = this.value.education.findIndex(
          (dt) => !dt.level || !dt.title || !dt.university
        );
        if (emptyObj > -1) return true;
      }
      return false;
    },
  },
  watch: {
    immediate: true,
    value: {
      handler(val) {
        if (val) {
          if (!val.workExperiences) val.workExperiences = [];
          if (!val.education || val.education.length === 0) {
            val.education = [
              {
                level: null,
                title: null,
                university: null,
                otherUniversity: null,
              },
            ];
          }
          this.value = val;
        } else {
          this.value = {
            fullName: null,
            rating: null,
            id: null,
            firstName: null,
            lastName: null,
            birthPlace: null,
            birthDate: null,
            gender: null,
            country: null,
            province: null,
            city: null,
            address: null,
            photoUrl: null,
            zipCode: null,
            phone: null,
            email: null,
            status: null,
            hasPrograms: null,
            role: null,
            str: null,
            title: null,
            education: null,
            specialization: null,
            quota: null,
            nClient: null,
            workExperiences: [],
            problemHandled: null,
            clientAgeHandled: null,
            languages: null,
          };
        }
      },
    },
  },
  methods: {
    ...mapActions({
      loadUniversities: "general/loadUniversities",
    }),
    removeWorkExperience(index) {
      this.value.workExperiences.splice(index, 1);
    },
    addWorkExperience() {
      if (this.isEmptyworkExperiences) return;
      this.value.workExperiences.push({
        companyName: null,
        endYear: null,
        startYear: null,
      });
    },
    onSetOtherUniversity(e, index) {
      this.value.education[index].otherUniversity = e.target.value;
    },
    addEducation() {
      if (this.isEmptyEducation) return;
      this.value.education.push({
        level: null,
        title: null,
        university: null,
        otherUniversity: null,
      });
    },
    removeEducation(index) {
      this.value.education.splice(index, 1);
      if (this.value.education.length === 0) {
        this.value.education.push({
          level: null,
          title: null,
          university: null,
          otherUniversity: null,
        });
      }
    },
  },
  async mounted() {
    this.loadUniversities();
  },
};
</script>
<style>
.ui.search.dropdown > input.search,
.ui.selection.dropdown > .dropdown.icon {
  top: auto;
}
.ui.dropdown .menu > .item {
  font-size: 16px;
}
</style>